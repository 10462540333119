<template>
  <v-row>
    <!-- kitchen sink -->
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ `${$t('menu.nomenclador')} / ${$t('menu.flights')} / ${$t('lbl.searchAirport')}` }}</v-card-title>
        <v-card-text><AirportSearch></AirportSearch></v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import AirportSearch from './form/AirportSearch.vue'

export default {
  components: {
    AirportSearch,
  },
}
</script>
